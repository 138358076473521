<template>
  <div :class="{ 'popup-visible' : this.popups.PreviewProfilePopup }">
    <div class="popup-wrap" @click.self="closePopup">

      <div v-if="this.popups.PreviewProfilePopup" class="popup preview-profile" :class="{ 'active' : this.popups.PreviewProfilePopup }">

          <div class="popup__header">
            <div class="popup__title">
              <span class="title-text">{{userdata.user_profile.name}} profile page</span>
            </div>
            <div class="popup-action ml-auto">
              <div class="popup-action__item popup-action__item_remove" @click.prevent="closePopup">
                <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333" />
                </svg>
              </div>
            </div>
          </div>

          <div class="popup__content">

            <div class="popup__section">

              <div class="section">
                <div class="section__header">
                  <div class="section__wysiwyg wysiwyg">
                    <h2>Basic information</h2>
                  </div>
                </div>

                <div class="section__content">
                  <div class="dl">
                    <div class="dl__list">
                      <div class="row">
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title">
                              <span class="title-text">First Name</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.firstname}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title">
                              <span class="title-text">Last Name</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.lastname}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title">
                              <span class="title-text">Country</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.country}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title">
                              <span class="title-text">City /Town</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.city}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title">
                              <span class="title-text">Position</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.position}}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title"
                              v-if="userdata.user_profile.company_user_type == 'business' ||
                              userdata.user_profile.company_user_type == 'expert' " >
                              <span class="title-text">Company</span>
                            </div>
                            <div class="dl__title"
                              v-else-if="userdata.user_profile.company_user_type == 'investment' ||
                              userdata.user_profile.company_user_type == 'investor' " >
                              <span class="title-text">Business</span>
                            </div>
                            <div v-else class="dl__title">
                              <span class="title-text">Start-Up Name</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">
                                <router-link :to="{name: 'company_profile', params: {id: userdata.user_profile.companyid }}">{{userdata.user_profile.companyname}}</router-link>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="col col_6 col_mob-12">
                          <div class="dl__item">
                            <div class="dl__title"
                              v-if="userdata.user_profile.company_user_type == 'business' ||
                              userdata.user_profile.company_user_type == 'expert' " >
                              <span class="title-text">Field of Expertise</span>
                            </div>
                            <div class="dl__title"
                              v-else-if="userdata.user_profile.company_user_type == 'investment' ||
                              userdata.user_profile.company_user_type == 'investor' " >
                              <span class="title-text">Field of Focus</span>
                            </div>
                            <div v-else  class="dl__title">
                              <span class="title-text">Industry</span>
                            </div>

                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.companyindustry}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="col col_6 col_mob-12"
                          v-if="userdata.user_profile.company_user_type == 'investment' ||
                          userdata.user_profile.company_user_type == 'investor' " >
                          <div class="dl__item">
                            <div class="dl__title">
                              <span class="title-text">Type of Investment</span>
                            </div>
                            <div class="dl__value">
                              <span class="value-text">{{userdata.user_profile.type_of_investment}}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div v-if="userdata.profile_pdf.description" class="section">
                <div class="section__header">
                  <div class="section__wysiwyg wysiwyg">
                    <h2>Description</h2>
                  </div>
                </div>
                <div class="section__content">
                  <div class="wysiwyg">{{ userdata.profile_pdf.description }}</div>
                </div>
              </div>

              <div v-if="userdata.profile_pdf.experience.length > 0" class="section">
                <div class="section__header">
                  <div class="section__wysiwyg wysiwyg">
                    <h2>Work experience</h2>
                  </div>
                </div>
                <div class="section__content">
                  <div v-for="(experience, key) in userdata.profile_pdf.experience" :key='key' class="sub-section">
                    <div class="sub-section__content">
                      <div class="wysiwyg">
                        <h3>{{experience?.title}}</h3>
                        <div class="exp-formatted-date">
                          <p>{{experience?.company}}</p>
                          <p v-if="experience?.date">{{ calculateEmployment(experience) }}</p>
                          <ul><li class="list__item">{{parseDate(experience?.date[0])}} - {{parseDate(experience?.date[1])}}</li></ul>
                        </div>
                        <p>{{experience?.description}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="userdata.profile_pdf.education.length > 0" class="section mb_10">
                <div class="section__header">
                  <div class="section__wysiwyg wysiwyg">
                    <h2>Education</h2>
                  </div>
                </div>
                <div class="section__content">
                  <div v-for="(education, key) in userdata.profile_pdf.education" :key='key' class="sub-section">
                    <div class="sub-section__content">
                      <div class="wysiwyg">
                        <div class="inline_items">
                          <h3>{{education?.school}}</h3>
                          <ul>
                            <li v-if="education?.degree" class="list__item">{{education.degree}}</li>
                            <li v-if="education?.date" class="list__item">{{parseDate(education?.date[0], true)}} - {{parseDate(education?.date[1], true)}}</li>
                          </ul>
                        </div>
                        <p>Extra Curriculars:</p>
                        <p>{{education?.description}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="popup__footer">
            <div class="btn-group btn-group_secondary content-between">
              <a href="#" @click.prevent="closePopup" class="btn btn_secondary">
                <span class="btn__text">Cancel</span>
              </a>
              <a href="#" @click.prevent="showConfirmPopup = true" class="btn btn_primary">
                <span class="btn__text">Save</span>
              </a>
            </div>
          </div>

      </div>
    </div>

    <div :class="{'popup-wrap': showConfirmPopup}">
      <div class="popup successfully-popup" :class="{'active': showConfirmPopup}">
        <div class="popup__header">
          <div class="popup__title">
            <span class="title-text">Overwrite Data</span>
          </div>
          <div class="popup-action ml-auto">
            <div class="popup-action__item popup-action__item_remove">
              <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"></path>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"></path>
              </svg>
            </div>
          </div>
        </div><!-- //popup__header -->
        <div class="popup__content">
          <div class="popup__section">
            <div class="wysiwyg">
              <p>Overwrite the existing profile data with the new profile information from PDF?</p>
            </div>
          </div>
        </div>
        <div class="popup__footer">
          <div class="btn-group btn-group_secondary content-right">
            <a @click.prevent="closePopup" href="#" class="btn btn_secondary popup-init">
              <span class="btn__text">No</span>
            </a>
            <a @click.prevent="saveProfile" href="#" class="btn btn_primary">
              <span class="btn__text">Yes</span>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "PreviewProfilePopup",
  components: {},
  data() {
    return {
      file: '',
      showConfirmPopup: false,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    }
  },
  methods: {
    closePopup() {
      this.file = '';
      this.showConfirmPopup = false;
      store.dispatch('SHOW_POPUP', { popup: 'PreviewProfilePopup', show: false});
      this.$router.go();
    },
    async saveProfile() {
      let status = await store.dispatch('USER_UPDATE_FROM_PDF', this.userdata.profile_pdf.id);
      if(status) this.closePopup();
    },
    parseDate(item, onlyYear = false) {
      if(item) {
        if(item === 'Present') return 'Present';
        if(/^\d+$/.test(item)) return item;
        let date = new Date(item);
        if(onlyYear) return date.getFullYear();
        return this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
      }
      return item;
    },
    calculateEmployment(item) {
      if(item?.date) {
        if(item.date[0]) {
          if(item.date[1]) {
            let start = Date.parse(item.date[0])
            let end = (item.date[1] === 'Present') ? Date.now() : Date.parse(item.date[1]);
            let range = (end - start) / 2629743000; // to month count
            if(range > 0) return ' - '+ (parseFloat(range/12).toFixed(1)) + ' years of employment';
          }
        }
      }
      return;
    },

  },
  computed: {
    popups: () => store.state.popups,
    userdata: () => store.state.user,
  },
}
</script>
